<template>
  <div>
    <div class="user">
      <div class="box">
        <div class="left">
          <div class="img">
            <img
              src="https://img0.baidu.com/it/u=1000906232,3488587097&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1683392400&t=3385f79e3e60f7108863c90a29e57005"
            />
          </div>

          <div class="info">
            <p class="phone">{{ userInfo.phone }}</p>
            <div class="tag">
              <!-- <el-tag>
                <span style="font-size: 14px">累积邀请人数：</span>
                <span style="font-size: 14px">0人</span>
              </el-tag>
              <el-tag>
                <span style="font-size: 14px">累积充值金额：</span>
                <span style="font-size: 14px">0</span>
              </el-tag>

              <el-tag>
                <span style="font-size: 14px">累积获得积分：</span>
                <span style="font-size: 14px">0</span>
              </el-tag> -->
            </div>
          </div>
        </div>
        <div class="right">
          <el-button type="primary">联系客服</el-button>
        </div>
      </div>
    </div>

    <!-- <div class="result">
      <span></span>
      <p>奖励明细</p>
    </div>

    <div class="table">
      <el-tag>
        <p style="font-size: 13px">
          通过邀请链接或者邀请码，引导新注册用户购买会员或积分，双方可额外获得15%积分奖励。
        </p>
      </el-tag>
      <el-table
        :data="invite.list"
        style="width: 100%"
        max-height="300"
        header-cell-style="background: #f5f7fa;"
      >
        <el-table-column align="center" prop="date" label="序号" width="100">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="uu_phone"
          label="被邀请人账号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="购买项目"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="amount"
          label="购买金额"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="integral"
          label="奖励积分"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="createtime"
          label="创建时间"
        ></el-table-column>
      </el-table>
    </div> -->
  </div>
</template>
  
  <script>
import { info, inviteData } from "@/api/user";
export default {
  data() {
    return {
      invite: [],
      userInfo: {},
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    copy(data) {
      let url = data;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message.success("复制成功!");
    },
    handleClick() {},
    infoData() {
      info().then((res) => {
        this.userInfo = res.data;
        this.$store.commit("user", res.data);
      });
    },

    getUser() {
      info().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.userInfo = res.data;
        }
      });
    },
    inviteData() {
      inviteData().then((res) => {
        this.invite = res.data;
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.el-tag {
  font-weight: 500;
  margin-right: 10px;
}
.user {
  padding-top: 15px;
}
.search {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .left {
    width: 60%;
    display: flex;
    align-items: flex-end;
    .box {
      display: flex;
      //   /deep/ .el-input {
      //     width: 50px;
      //     margin-right: 20px;
      //     margin-left: 20px;
      //   }
    }
    .el-button {
      margin-left: 20px;
    }
    .input-with-select {
      width: 300px;
    }
  }
  .right {
    width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .box {
      display: flex;
      align-items: center;
    }
  }
}

.user {
  width: 100%;
  .box {
    width: 95%;
    padding-bottom: 30px;
    border-bottom: 1px solid #f5f6f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    .left {
      width: 70%;
      display: flex;
      align-items: center;
      .img {
        width: 80px;
        height: 80px;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .info {
        .phone {
          font-size: 20px;
          font-weight: 800;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.url {
  display: flex;
  margin-top: 20px;
  .list {
    margin-right: 100px;

    .el-input {
      width: 350px;
      margin-right: 20px;
    }
  }
}

.copywriting {
  margin-top: 20px;
  .el-input {
    margin-bottom: 10px;
    margin-right: 20px;
  }
}

.table {
  width: 95%;
  margin: 20px auto;
  .el-tag {
    margin-bottom: 20px;
  }
}

.datas {
  margin-top: 10px;
}

.result {
  width: 95%;
  display: flex;
  align-items: center;
  margin: 20px auto;
  span {
    width: 5px;
    height: 15px;
    background: #3363ff;
    display: block;
  }
  p {
    font-size: 18px;
    font-weight: 800;
    margin-left: 10px;
  }
}

// .info {
//   .data {
//     display: flex;
//     .img {
//       width: 215px;
//       height: auto;
//       margin-right: 20px;
//       img {
//         width: 100%;
//       }
//     }
//     .box {
//       .title {
//         font-size: 20px;
//         font-weight: 700;
//       }
//       .dp {
//         font-size: 13px;
//         font-weight: 500;
//         height: 35px;
//         line-height: 35px;
//         opacity: 0.7;
//         display: block;
//       }
//       .price {
//         display: flex;
//         span {
//           color: red;
//           font-weight: 800;
//           font-size: 16px;
//           margin-top: 10px;
//         }
//         .yj {
//           font-size: 16px;
//           color: #000000;
//           margin-top: 10px;
//           margin-bottom: 10px;
//         }
//       }
//       .item {
//         display: flex;
//         align-items: center;
//         .list {
//           width: 100px;
//           height: 30px;
//           line-height: 30px;
//           background: rgb(243, 244, 245);
//           display: flex;
//           justify-content: space-between;
//           margin-right: 10px;
//           border-radius: 5px;
//           padding: 3px 10px;
//           font-size: 14px;
//           p {
//             opacity: 0.7;
//           }
//           span {
//             font-weight: 800;
//           }
//         }
//       }
//       .button {
//         margin-top: 10px;
//       }
//       .datas {
//         width: 50%;
//         display: flex;
//         position: absolute;
//         right: 100px;
//         top: 27%;
//         .datas-list {
//           width: 30%;
//           display: flex;
//           background: #f3f4f5;
//           display: flex;
//           justify-content: center;
//           flex-direction: column;
//           align-items: center;
//           padding: 10px 10px;
//           margin-right: 10px;
//           border-radius: 10px;
//           p {
//             font-size: 20px;
//             font-weight: 800;
//             margin-bottom: 3px;
//           }
//           span {
//           }
//         }
//       }
//     }
//   }
// }

/deep/ .el-tabs__item.is-active {
  color: #f57a3d !important;
}
/deep/ .el-tabs__item {
  font-size: 18px;
  padding: 0 10px;
  color: #999999;
}
/deep/ .el-tabs__active-bar {
  background: #f57a3d !important;
}
/deep/ .el-tabs__nav-wrap::after {
  background-color: #fff;
}
/deep/ .el-tabs__header {
  margin: 0px 0px 0px 0px !important;
}

.con {
  margin-top: 20px;
  .table {
    margin-top: 20px;
  }
}
</style>